html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100%;
  display: flex;
  flex-flow: column;
}
